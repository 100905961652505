<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-15 09:38:22
 * @LastEditTime: 2023-06-30 17:06
 * @Descripttion: 【内容】教材管理
-->
<style lang="scss" scoped>
    .textbook {
        @include innerPage(40px 34px 48px 15px);
        @include pageTitle(0);
        @include defalutTable(calc(100% - 104px), 20px);
        @include pageFoot;

        .page-title {
            flex-wrap: wrap;

            .search-form {
                width: 78.67%;
                min-width: 1000px;

                &-wrapper {
                    width: 100%;
                    margin-top: 16px;
                }
            }

            .el-form-item {
                width: 16%;
                max-width: 190px;
                min-width: 164px;

                &:last-child {
                    flex-grow: 1;
                    max-width: 340px;
                }
            }
        }
    }

    .dialog-container {
        width: calc(100% + 20px);
        max-height: 380px;
        box-sizing: border-box;
        padding: 10px 20px 0 18px;
        margin: 24px 0 48px;
        overflow: hidden;
        overflow-y: auto;

        .tips {
            color: #e58181;
            height: 24px;
            position: absolute;
            top: 24px;
            right: 120px;
            font-size: 14px;
            @include flexBox;

            .iconfont {
                font-size: 20px;
                margin-right: 4px;
            }
        }

        .reason {
            color: #e58181;
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 20px;
            @include flexBox;
            align-items: flex-start;

            span {
                flex-shrink: 0;
            }
        }

        .el-form-item:last-child {
            margin-bottom: 0;
        }
    }
</style>

<template>
    <section class="textbook">
        <div class="page-title">
            <h3>教材管理</h3>
            <el-button type="custom_primary" size="medium" @click="showDialog">
                添加教材 +
            </el-button>
            <div class="search-form-wrapper">
                <div class="search-form">
                    <el-form inline :model="searchForm">
                        <el-form-item>
                            <el-select v-model="searchForm.sccou_grade" placeholder="请选择年级" clearable filterable>
                                <el-option v-for="item in gradeList" :key="item.grade_id" :label="item.grade_name"
                                           :value="item.grade_id"/>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="searchForm.semester" placeholder="请选择学期" clearable>
                                <el-option label="上学期" :value="1"/>
                                <el-option label="下学期" :value="2"/>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="searchForm.sysub_id" placeholder="请选择科目" clearable filterable>
                                <el-option v-for="item in subjectList" :key="item.sysub_id" :label="item.sysub_name"
                                           :value="item.sysub_id"/>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model.trim="searchForm.query_word" maxlength="100" placeholder="输入关键字">
                                <span slot="prefix" class="iconfont">&#xe61c;</span>
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <el-button type="custom_primary" size="medium" @click="searchData">查询</el-button>
                </div>
            </div>
        </div>
        <div class="table-box">
            <div class="table-inner" ref="tableInner">
                <el-table :data="tableData" height="100%">
                    <el-table-column align="center" prop="sccou_grade_name" label="年级" min-width="6.75%"/>
                    <el-table-column align="center" prop="semester_name" label="学期" min-width="6.63%"/>
                    <el-table-column align="center" prop="sysub_name" label="科目 " min-width="6.05%"/>
                    <el-table-column align="center" prop="syedi_title" label="版本" min-width="7.19%"/>
                    <el-table-column align="center" prop="syedi_version" label="版号" min-width="10.45%"/>
                    <el-table-column align="center" prop="sccou_origin" label="来源" min-width="17.45%"/>
                    <el-table-column align="center" prop="time" label="添加时间" min-width="11.91%">
                        <template slot-scope="scope">
                            {{ scope.row.create_time * 1000 | formatTime }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="审核状态" min-width="8.03%">
                        <template slot-scope="scope">
                            <p class="table-status"
                               :class="{ failed: scope.row.sccou_sharestatus == 30, success: scope.row.sccou_sharestatus == 10, warning: scope.row.sccou_sharestatus == 20 }">
                                {{ scope.row.sccou_sharestatus == 30 && '已驳回' || scope.row.sccou_sharestatus == 10 &&
                                '已通过' || scope.row.sccou_sharestatus == 5 && '未共享' || '待审核' }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="启用/禁用" min-width="8.41%">
                        <template slot-scope="scope">
                            <el-switch class="text-inner" v-model="scope.row.sccou_sho_status" :active-value="10"
                                       :inactive-value="20" inactive-text="启用" active-text="禁用"
                                       v-if="scope.row.sccou_sharestatus == 10 || scope.row.sccou_sharestatus == 5"
                                       @change="changeStatus(scope.row.sccou_id, scope.row.sccou_sho_status, scope.$index)"/>
                            <p v-if="scope.row.sccou_sharestatus != 10 && scope.row.sccou_sharestatus != 5">-</p>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" min-width="17.13%">
                        <template slot-scope="scope">
                            <el-button style="margin: 0 auto" type="custom_success" size="mini" plain
                                       v-if="scope.row.is_myschool != 1"
                                       @click="$router.push({ name: 'CONTENT_TEXTBOOK_CHECKCHAPTERS', params: { id: scope.row.sccou_id } })">
                                查看章节
                            </el-button>
                            <template v-if="scope.row.is_myschool == 1">
                                <el-button type="custom_primary" size="mini" plain
                                           :disabled="scope.row.sccou_sharestatus == 20" @click="showDialog(scope.row)">
                                    编辑
                                </el-button>
                                <el-button type="custom_warning" size="mini" plain
                                           :disabled="scope.row.sccou_sharestatus == 20"
                                           @click="$router.push({ name: 'CONTENT_TEXTBOOK_CHAPTERS', params: { id: scope.row.sccou_id } })">
                                    章节管理
                                </el-button>
                                <el-button @click="shareData(scope.row.sccou_id)"
                                           v-if="scope.row.sccou_sharestatus == 5"
                                           type="custom_success" size="mini" plain>
                                    共享
                                </el-button>
                                <el-button v-if="scope.row.sccou_sharestatus == 30" type="custom_success" size="mini"
                                           plain
                                           @click="shareData(scope.row.sccou_id)">
                                    重新共享
                                </el-button>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-foot custom-foot">
                <customPagination :current='tablePage.pageIndex' :total="tablePage.total" @pageChange="flippingPage"/>
            </div>
        </div>
        <!-- 新增弹窗 -->
        <el-dialog :title="textbookForm.sccou_id && '编辑教材' || '添加教材'" width="558px" :visible.sync="textbookDialog"
                   :show-close="false" @close="hideDialog">
            <div class="dialog-container">
                <p class="tips">
                    <i class="iconfont">&#xe63c;</i>
                    {{ dataAudit.sccou_sharestatus == 30 && '已驳回' || '教材提审需平台审核，请耐心等待...' }}
                </p>
                <div class="reason" v-if="dataAudit.sccou_sharestatus == 30">
                    <span>驳回理由：</span>
                    <p>
                        {{ dataAudit.sccou_reason }}
                    </p>
                </div>
                <el-form class="page-form" label-width="68px" ref="textbookForm" :model="textbookForm" :rules="rules">
                    <el-form-item label="年级" prop="sccou_grade">
                        <el-select v-model="textbookForm.sccou_grade" placeholder="请选择年级" filterable clearable>
                            <el-option v-for="item in gradeList" :key="item.grade_id" :label="item.grade_name"
                                       :value="item.grade_id"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="学期" prop="semester">
                        <el-select v-model="textbookForm.semester" placeholder="请选择学期" clearable>
                            <el-option label="上学期" :value="1"/>
                            <el-option label="下学期" :value="2"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="科目" prop="sysub_id">
                        <el-select v-model="textbookForm.sysub_id" placeholder="请选择科目" filterable clearable>
                            <el-option v-for="item in subjectList" :key="item.sysub_id" :label="item.sysub_name"
                                       :value="item.sysub_id"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="教材版本" prop="syedi_title">
                        <el-input v-model.trim="textbookForm.syedi_title" maxlength="50" placeholder="请输入内容" clearable/>
                    </el-form-item>
                    <el-form-item label="版号" prop="syedi_version">
                        <el-input v-model.trim="textbookForm.syedi_version" maxlength="100" placeholder="请输入内容"
                                  clearable/>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer"
                 :class="{ 'foot-flex': !textbookForm.sccou_id || dataAudit.sccou_sharestatus == 30 || dataAudit.sccou_sharestatus == 5 }">
                <el-button type="custom_info" size="small" @click="hideDialog">
                    取 消
                </el-button>
                <el-button type="custom_primary" size="small" :disabled="btnload" v-loading="btnload" @click="addData">
                    保 存
                </el-button>
                <el-button style="margin-left:auto" type="custom_success" size="small"
                           v-if="!textbookForm.sccou_id || dataAudit.sccou_sharestatus == 30 || dataAudit.sccou_sharestatus == 5"
                           @click="shareData(textbookForm.sccou_id || null)">
                    {{ dataAudit.sccou_sharestatus == 30 && '重新共享' || '共 享' }}
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
    import {$textbookList, $textbookAdd, $textbookEdit, $textbookStatusChange, $textbookShartet} from "@api/content"
    import {mapState} from "vuex";
    import customPagination from "@comp/customPagination"

    export default {
        name: 'content_textbook',
        components: {customPagination},
        computed: {
            ...mapState('common', ['gradeList', 'subjectList'])
        },
        data() {
            return {
                searchForm: {}, // 搜索参数
                tableData: [], // 表格参数
                /* 表格分页参数 */
                tablePage: {
                    pageIndex: 1,
                    total: 0
                },
                textbookDialog: false, // 教材编辑显示弹窗
                textbookForm: {}, // 教材编辑表单
                dataAudit: {}, // 教材审核内容
                btnload: false,
                /* 表单校验规则 */
                rules: {
                    sccou_grade: [
                        {required: true, type: 'number', message: '请选择年级', trigger: 'change'},
                    ],
                    semester: [
                        {required: true, type: 'number', message: '请选择学期', trigger: 'change'},
                    ],
                    sysub_id: [
                        {required: true, type: 'number', message: '请选择教材科目', trigger: 'change'},
                    ],
                    syedi_title: [
                        {required: true, message: '请输入教材版本名称', trigger: 'blur'},
                    ],
                    syedi_version: [
                        {required: true, message: '请输入教材版号', trigger: 'blur'},
                    ],
                }
            };
        },
        created() {
            this.getData();
        },
        methods: {
            /** 获取教材数据 */
            async getData() {
                let params = {
                    ...this.searchForm,
                    pageindex: this.tablePage.pageIndex
                }
                let {data: res} = await $textbookList(params);
                this.tablePage.total = res.allcount;
                this.tableData = res.data;
                this.$forceUpdate();
            },
            /** 添加教材 */
            addData() {
                this.$refs.textbookForm.validate(async (valid) => {
                    if (valid) {
                        this.btnload = true;
                        let {sccou_id: id} = this.textbookForm;
                        let params = {...this.textbookForm}
                        if (id) return this.editData();
                        let res = await $textbookAdd(params);
                        this.btnload = false;
                        if (res) {
                            this.hideDialog();
                            this.$message.success('添加成功');
                            this.getData();
                        }
                    }
                })
            },
            /** 编辑教材 */
            async editData() {
                let params = {...this.textbookForm}
                let res = await $textbookEdit(params);
                this.btnload = false;
                if (res) {
                    this.hideDialog();
                    this.$message.success('编辑成功');
                    this.getData();
                }
            },
            /**
             * 修改教材状态
             * @param {number} id 教材id
             * @param {number} type 教材状态
             */
            async changeStatus(id, type, index) {
                let res = await $textbookStatusChange(id, type);
                if (res) {
                    this.$message.success('教材状态修改成功');
                    this.getData();
                } else {
                    this.tableData[index].sccou_sho_status = type == 10 && 20 || 10;
                }
            },
            /** 共享教材 */
            shareData(id) {
                let valid_suc = false;
                let params;
                /* 判断共享时，是否正在编辑或添加教材 */
                if (this.textbookDialog) {
                    this.$refs.textbookForm.validate((valid) => {
                        if (valid) {
                            valid_suc = true;
                            params = {...this.textbookForm, saveType: 2}
                        }
                    })
                }
                /* 共享时，正在添加或编辑教材但表单校验未通过 */
                if (this.textbookDialog && !valid_suc) return;
                /* 共享时，正在添加或编辑教材且表单校验通过 或 教材未处在编辑或添加状态 */
                if (!this.textbookDialog || (this.textbookDialog && valid_suc)) {
                    this.$msgbox({
                        title: "提示",
                        message: "您的教材共享通过之后，该教材章节管理权限将进入平台，请谨慎操作！",
                        type: "info",
                        showClose: false,
                        showCancelButton: true,
                        cancelButtonClass: "el-button--custom_info",
                        confirmButtonClass: "el-button--custom_primary",
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                    }).then(async () => {
                        // 操作共享请求
                        let res;
                        /* 添加保存并共享教材 */
                        if (!id) {
                            res = await $textbookAdd(params);
                        }
                        /* 编辑保存并共享教材 */
                        else if (this.textbookDialog) {
                            res = await $textbookEdit(params);
                        }
                        /* 直接共享教材 */
                        else {
                            res = await $textbookShartet(id);
                        }
                        if (res) {
                            this.$message({
                                type: 'success',
                                duration: 1500,
                                message: '教材已共享！'
                            });
                            this.getData();
                            if (this.textbookDialog) this.hideDialog();
                        }
                    }).catch(() => {
                        this.$message({
                            type: "info",
                            duration: 1500,
                            message: "操作已取消",
                        });
                    });
                }

            },
            /** 搜索 */
            searchData() {
                this.tableData = [];
                this.tablePage.pageIndex = 1;
                this.getData();
            },
            /** 翻页 */
            flippingPage(val) {
                this.tablePage.pageIndex = val;
                this.getData();
            },
            /** 开启弹窗 */
            showDialog(data) {
                this.textbookDialog = true;
                if (data.sccou_id) {
                    let keys = ['sccou_id', 'sccou_grade', 'semester', 'syedi_title', 'syedi_version', 'sysub_id'];
                    let form = {}
                    keys.map(key => {
                        form[key] = data[key];
                    });
                    this.dataAudit = {
                        sccou_sharestatus: data.sccou_sharestatus,
                        sccou_reason: data.sccou_reason,
                    }
                    this.textbookForm = {...form}
                }
            },
            /** 关闭弹窗 */
            hideDialog() {
                this.textbookDialog = false;
                this.dataAudit = {};
                this.resetForm('textbookForm');
                this.textbookForm = {};

            },
            /** 重置表单 */
            resetForm(ref) {
                this.$refs[ref].resetFields();
            }
        },
    }
</script>