<template>
  <div class="foot-page">
    <el-pagination prev-text="上一页" next-text="下一页" layout="prev, pager, next" background :current-page="current"
      :page-size="size" :total="total" @current-change="flippingPage" />
    <div class="page-jump">
      <div class="jump-ipt">
        <span>到</span>
        <input v-model="iptVal" @input="iptVal = iptInit(iptVal)" @keyup.enter="pageJump" ref="pageIpt" />
        <span>页</span>
      </div>
      <el-button type="custom_primary" size="medium" @click="pageJump">确定</el-button>
    </div>
  </div>
</template>

<script>
import { PAGE_SIZE } from "@config";
import { iptInteger } from "@utils"
export default {
  name: "customPagination",
  iptInit() {
    return function (val) {
      return iptInteger(val, '')
    }
  },
  props: {
    total: {
      type: Number,
      value: 0,
    },
    current: {
      type: Number,
      value: 1,
    },
    size: {
      type: Number,
      value: PAGE_SIZE,
    },
  },
  data() {
    return {
      pageCurrent: 1,
      iptVal: "",
    };
  },
  methods: {
    flippingPage(val) {
      this.fnEmit(val);
    },
    pageJump() {
      let val = this.iptVal;
      let size = this.size || PAGE_SIZE;
      let pageCount = Math.ceil(this.total / size);
      pageCount = (pageCount < 1 && 1) || pageCount;
      this.$refs.pageIpt.blur();
      this.iptVal = "";
      if (val > pageCount || val == this.current || val < 1) return;
      this.fnEmit(val);
    },
    fnEmit(val) {
      this.$emit("pageChange", parseInt(val));
    },
  },
};
</script>